<template>
  <v-avatar class="grey lighten-2" :size="size" :title="getName(user)">
    <template v-if="user.avatar && !is_error">
      <v-img
        :src="$imagehelpers.childAvatar(user.avatar, size)"
        :title="getName(user)"
        @error="is_error = true"
        :alt="getName(user)"
        @click="onClick"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </template>
    <template v-if="!user.avatar || is_error">
      <v-icon :style="getEmptyStyle()">$vuetify.icons.farUser</v-icon>
    </template>
  </v-avatar>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      is_error: false,
    };
  },
  methods: {
    onClick() {
      this.$emit("clicked", this.user.avatar);
    },
    getEmptyStyle() {
      var fontSize = this.size * 0.6;

      return "font-size: " + fontSize + "px !important";
    },
    getName(user) {
      if (user.name) return user.name + " (" + user.username + ")";
      return user.username;
    },
  },
};
</script>