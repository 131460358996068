import { imageHelpers, enums, dateHelpers, childMemories } from "../_helpers";

export const timelineCreator = {
  formatedTimeline,
};

function formatedTimeline(dataArray, diary) {
  const data = [];
  const length = dataArray.length;

  for (let i = 0; i < length; i++) {
    data.push(createTimelineObject(dataArray[i], diary));
  }

  return data;
}

function createTimelineObject(item, diary) {
  const obj = new Object();
  obj.id = item.id;
  obj.date = dateHelpers.getFormatedTimelineDate(item.date);
  obj.icon = getIcon(item, diary);
  //obj.header = getFormatedHeader(item);
  //obj.content = getFormatedContent(item);
  obj.media = item.media;
  obj.child = item.child;
  obj.event_data = item.event_data;
  obj.event_type = item.event_type;
  obj.event_date = item.date;

  return obj;
}

function getIcon(item, diary) {
  if (!item.event_type) {
    throw new Error("Type is not defined");
  }

  var defaultIcon = getDefaultIcon(item);

  switch (item.event_type) {
    case enums.FEEDEVENTS.BLOGPOST:
      return {
        color: "white",
        bgcolor: "#457b9d",
        is_icon: true,
        src: defaultIcon,
        default_icon: defaultIcon,
        title: IfNullThen(item.event_data.title, ""),
        url: { name: "diary_blog", params: { diary: diary.name } },
      };

    case enums.FEEDEVENTS.MEDIAALBUM:
      return {
        color: "white",
        bgcolor: "#457b9d",
        is_icon: true,
        src: defaultIcon,
        default_icon: defaultIcon,
        title: IfNullThen(item.event_data.title, ""),
        url: { name: "diary_albums", params: { diary: diary.name } },
      };

    case enums.FEEDEVENTS.CHILDBIRTH:
    case enums.FEEDEVENTS.CHILDEVENT:
    case enums.FEEDEVENTS.CHILDSIZE:
    case enums.FEEDEVENTS.CHILDMEMORY:
    case enums.FEEDEVENTS.CHILDBIRTHDAYS: {
      var childUrl = {
        name: "child_home",
        params: { diary: diary.name, name: item.child.url_name },
      };
      if (item.child && item.child.avatar) {
        return {
          color: "white",
          bgcolor: "#457b9d",
          is_icon: false,
          src: imageHelpers.childAvatar(item.child.avatar, 50),
          default_icon: defaultIcon,
          title: IfNullThen(item.child.name, ""),
          url: childUrl,
        };
      } else {
        return {
          color: "white",
          bgcolor: "#457b9d",
          is_icon: true,
          src: defaultIcon,
          default_icon: defaultIcon,
          title: IfNullThen(item.child.name, ""),
          url: childUrl,
        };
      }
    }

    case enums.FEEDEVENTS.OWNEVENT:
      return {
        color: "white",
        bgcolor: "#457b9d",
        is_icon: true,
        src: defaultIcon,
        url: "",
      };

    case enums.FEEDEVENTS.INSTAGRAM:
      return {
        color: "white",
        bgcolor: "#457b9d",
        is_icon: true,
        src: defaultIcon,
        default_icon: defaultIcon,
        title: "Instagram",
        url: "",
      };

    case enums.FEEDEVENTS.FACEBOOK:
    case enums.FEEDEVENTS.FACEBOOKPAGE:
      return {
        color: "white",
        bgcolor: "#457b9d",
        is_icon: true,
        src: defaultIcon,
        default_icon: defaultIcon,
        title: "Facebook",
        url: "",
      };
  }
  return {
    color: "white",
    bgcolor: "#457b9d",
    is_icon: true,
    src: "$vuetify.icons.fasStar",
    default_icon: defaultIcon,
    url: "",
  };
}

function getDefaultIcon(item) {
  switch (item.event_type) {
    case enums.FEEDEVENTS.BLOGPOST:
      return "$vuetify.icons.fasNewspaper";

    case enums.FEEDEVENTS.MEDIAALBUM:
      return "$vuetify.icons.fasImages";

    case enums.FEEDEVENTS.CHILDBIRTH:
      return "$vuetify.icons.fasBaby";

    case enums.FEEDEVENTS.CHILDBIRTHDAYS:
      return "$vuetify.icons.fasBirthdayCake";

    case enums.FEEDEVENTS.CHILDEVENT:
      return "$vuetify.icons.fasChild";

    case enums.FEEDEVENTS.CHILDSIZE:
      return "$vuetify.icons.fasChild";

    case enums.FEEDEVENTS.OWNEVENT:
      return "$vuetify.icons.fasStar";

    case enums.FEEDEVENTS.CHILDMEMORY:
      return getChildMemoryIcon(item);

    case enums.FEEDEVENTS.INSTAGRAM:
      return "$vuetify.icons.fabInstagram";

    case enums.FEEDEVENTS.FACEBOOK:
    case enums.FEEDEVENTS.FACEBOOKPAGE:
      return "$vuetify.icons.fabFacebookF";
  }
  return "$vuetify.icons.fasStar";
}

function getChildMemoryIcon(item) {
  if (item.event_data && item.event_data.memory) {
    var memory = item.event_data.memory;

    var src = childMemories.getList().find((p) => p.key == memory.url_name);

    if (src && src.icon) return src.icon;
  }
  return "$vuetify.icons.fasStar";
}

function IfNullThen(key, defaultVal) {
  if (key) return key;
  if (defaultVal) return defaultVal;
  return null;
}
