<template>
  <span>
    <form-dialog
      v-model="is_open"
      :title="$t('Welcome')"
      v-on:submit="handleSubmit"
      v-on:close="handleClose"
      :submit-text="$t('Button.Done')"
      max-width="500px"
      :is-saving="is_saving"
    >
      <template v-slot:content>
        <v-form v-model="form" ref="form" autocomplete="off">
          <v-alert dense outlined class="body-2 mb-5" color="primary">
            <span
              v-if="settings.includes('username')"
              v-html="$t('WelcomeWithUsernameDesc')"
              class="pa-0"
            ></span>
            <span v-else v-html="$t('WelcomeDesc')"></span>
          </v-alert>

          <v-text-field
            v-if="settings.includes('username')"
            v-model="username"
            :rules="[
              (v) => !!v || $t('UsernameRequired'),
              (v) => (v && v.length <= 45) || $t('UsernameMaxLength'),
              (v) => (v && v.length >= 3) || $t('UsernameToShort'),
              (v) => /^[a-zA-Z0-9_.]+$/.test(v) || $t('UsernameNotValid'),
            ]"
            :disabled="is_saving"
            :label="$t('Username')"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            required
          ></v-text-field>

          <v-text-field
            v-if="settings.includes('diary')"
            v-model="diary_title"
            :rules="[
              (v) => !!v || $t('DiaryTitleIsMissing'),
              (v) => (v && v.length <= 45) || $t('DiaryTitleMaxLength'),
              (v) => (v && v.length >= 3) || $t('DiaryTitleToShort')
            ]"
            :disabled="is_saving"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            :label="$t('Title')"
            required
          ></v-text-field>

          <v-text-field
            v-model="diary_name"
            v-if="settings.includes('diary')"
            :rules="[
              (v) => !!v || $t('DiaryNameIsMissing'),
              (v) => (v && v.length <= 45) || $t('DiaryNameMaxLength'),
              (v) => (v && v.length >= 3) || $t('DiaryNameToShort'),
              (v) => /^[a-zA-Z0-9_.]+$/.test(v) || $t('DiaryNameNotValid'),
            ]"
            :disabled="is_saving"
            :hint="host + diary_name"
            persistent-hint
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            :label="$t('DiaryName')"
            required
          ></v-text-field>

          <v-select
            v-if="settings.includes('diary')"
            v-bind:items="[
              { text: $t('OnlyForMe'), value: $enums.DIARY_PRIVACY.OnlyForMe },
              { text: $t('OpenForAll'), value: $enums.DIARY_PRIVACY.OpenForAll },
              {
                text: $t('OnlyForMembers'),
                value: $enums.DIARY_PRIVACY.OnlyForMembers,
              },
            ]"
            v-model="diary_privacy"
            :disabled="is_saving"
            required
            class="mt-5"
            :label="$t('Availability')"
          ></v-select>

          <error-box :error="error"></error-box>
        </v-form>
      </template>
    </form-dialog>

    <v-overlay :value="page_loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </span>
</template>

<i18n>
    {
    "en": {
    "Welcome": "Welcome!",
    "WelcomeDesc": "Check if you want to use the suggested information below.<br /> The diary address cannot be changed later.",
    "WelcomeWithUsernameDesc": "Check if you want to use the suggested information below. <br /> Username and diary address cannot be changed later.",
    "Username": "Username",
    "Title": "Diary title",
    "DiaryTitleIsMissing": "Title is missing!",
    "DiaryName": "Diary address",
    "DiaryNameIsMissing": "Address is missing!",
    "SkipStep": "Skip this step",
    "Availability": "Availability",
    "OnlyForMe": "Only for me",
    "OpenForAll": "Open for all",
    "PasswordProtected": "Password protected",
    "OnlyForMembers": "Only open for members",
    "UsernameRequired": "Username is missing!",    
    "UsernameMaxLength": "Username must be less than 45 characters",
    "UsernameNotValid": "Username not valid. Use a-zA-Z0-9_.",
    "UsernameToShort": "Username to short. At least 3 characters",
    "DiaryTitleMaxLength": "Title must be less than 45 characters",
    "DiaryTitleToShort": "Title to short. At least 3 characters",
    "DiaryNameMaxLength": "Address must be less than 45 characters",
    "DiaryNameToShort": "Address to short. At least 3 characters",
    "DiaryNameNotValid": "Address not valid. Use a-zA-Z0-9_."
    },
    "sv": {
    "Welcome": "Välkommen!",
    "WelcomeDesc": "Kontrollera om du vill använda de föreslagna uppgifterna nedan.<br />Dagboksadressen går inte att ändra senare.",
    "WelcomeWithUsernameDesc": "Kontrollera om du vill använda de föreslagna uppgifterna nedan.<br />Användarnamn och dagboksadressen går inte att ändra senare.",
    "Username": "Användarnamn",
    "Title": "Titel",
    "DiaryTitleIsMissing": "Titel saknas!",
    "DiaryName": "Dagboksadress",
    "DiaryNameIsMissing": "Adress saknas!",
    "SkipStep": "Hoppa över detta steget",
    "Availability": "Tillgänglig",
    "OnlyForMe": "Enbart öppen för mig",
    "OpenForAll": "Öppen för alla",
    "PasswordProtected": "Lösenord krävs för att komma in",
    "OnlyForMembers": "Öppen för inloggade användare",
    "UsernameRequired": "Användarnamn saknas!",
    "UsernameMaxLength": "Användarnamnet får inte vara längre än 45 tecken",
    "UsernameNotValid": "Användarnamnet är inte korrekt. Använd a-zA-Z0-9_.",
    "UsernameToShort": "Användarnamnet för kort. Minst 3 tecken",
    "DiaryTitleMaxLength": "Titel får inte vara längre än 45 tecken",
    "DiaryTitleToShort": "Title to short. At least 3 characters",
    "DiaryNameMaxLength": "Adressen får inte vara längre än 45 tecken",
    "DiaryNameToShort": "Adressen för kort. Minst 3 tecken",
    "DiaryNameNotValid": "Adressen är inte korrekt. Använd a-zA-Z0-9_."
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "welcome_dialog",
  data: () => ({
    is_open: false,
    is_saving: false,
    page_loading: false,

    settings: [],
    form: false,
    host: "",

    username: "",
    diary_name: "",
    diary_title: "",
    diary_privacy: 1,

    error: null,
  }),
  computed: {
    ...mapState({
      current_user: (state) => state.account.user,
      current_diary: (state) => state.account.user.current_diary,
      visited_diary: (state) => state.diary,
    }),
  },
  created: function () {
    this.is_open = true;

    this.settings = this.current_user.data.WelcomeDiaryModal.trim().split(",");
    this.username = this.current_user.username;
    this.host = document.location.hostname + "/";

    if (this.current_diary) {
      this.diary_name = this.current_diary.name;
      this.diary_title = this.current_diary.title;
      this.diary_privacy = this.current_diary.privacy;

      if (!this.diary_privacy)
        this.diary_privacy = this.$enums.DIARY_PRIVACY.OpenForAll;
    }
  },
  methods: {
    ...mapActions({
      saveWelcome: "account/saveWelcome",
      closeWelcome: "account/closeWelcome",
    }),
    handleSubmit() {
      var self = this;
      self.error = null;
      self.is_saving = false;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        self
          .saveWelcome({
            username: self.username,
            diary_title: self.diary_title,
            diary_name: self.diary_name,
            diary_privacy: self.diary_privacy,
          })
          .then(function () {
              if(self.visited_diary && self.visited_diary.profile && self.visited_diary.profile.id == self.current_diary.id) {
                self.$store.dispatch("diary/updateWelcomeInfo", { title: self.diary_title, privacy: self.diary_privacy, name: self.diary_name }, { root: true });
              }

            self.$emit("done");
            self.close();
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    handleClose() {
      var self = this;

      if (self.is_saving) return;

      self.error = null;
      self.page_loading = true;

      self
        .closeWelcome()
        .then(function () {
          self.$emit("done");
          self.close();
        })
        .catch(function (error) {
          self.is_open = true;
          self.error = error;
          self.page_loading = false;
        });
    },
    close() {
      this.$emit("closed");
      this.is_open = false;

      var ref = this.$route.query.ref;

      if (ref) this.$router.push(ref);
      else if (this.$route.params.diary != this.current_diary.name)
        this.$router.push("/" + this.current_diary.name);

      this.page_loading = false;
    },
  },
};
</script>