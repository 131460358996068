<template>
  <v-app>
    <template v-if="!is_loading">
      <mobile-bar v-if="$isMobile"></mobile-bar>
      <main-bar v-if="!$isMobile"></main-bar>
      <v-main>
        <router-view></router-view>
      </v-main>
      <main-footer></main-footer>
    </template>

    <v-overlay :value="is_loading" color="primary">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <session-expired
      show-session-message
      v-model="show_login_dialog"
    ></session-expired>
    <welcome-dialog
      v-on:closed="startTimer"
      v-if="show_welcome"
    ></welcome-dialog>
  </v-app>
</template>

<script>
import MainBar from "@/components/main/Bar.vue";
import MobileMainBar from "@/components/main/MobileBar.vue";
import MainFooter from "@/components/main/Footer.vue";
import SessionExpiredDialog from "@/components/dialogs/AuthorizeDialog.vue";
import WelcomeDialog from "@/components/dialogs/WelcomeDialog.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  metaInfo() {
    return {
      titleTemplate: "%s | " + process.env.VUE_APP_NAME,
      htmlAttrs: {
        amp: false,
      },
      meta: [
        {
          property: "og:title",
          content: this.$t("MainPage.Title"),
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("MainPage.Description"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("MainPage.Description"),
          vmid: "description",
        },

        {
          property: "og:site_name",
          content: process.env.VUE_APP_NAME,
          vmid: "og:site_name",
        },
        { property: "og:type", content: "website", vmid: "og:type" },
        {
          property: "fb:app_id",
          content: process.env.VUE_APP_FACEBOOK_APP_ID,
          vmid: "fb:app_id",
        },
        {
          property: "og:locale",
          content: this.$t("MainPage.Locale"),
          vmid: "og:locale",
        },

        {
          property: "og:image",
          content: this.$t("MainPage.Banner"),
          vmid: "og:image",
        },
        { property: "og:image:width", content: "851", vmid: "og:image:width" },
        {
          property: "og:image:height",
          content: "315",
          vmid: "og:image:height",
        },
        {
          property: "twitter:card",
          content: "summary",
          vmid: "twitter:card",
        },
        {
          property: "twitter:image:src",
          content: this.$t("MainPage.Banner"),
          vmid: "twitter:image:src",
        },
        {
          property: "og:url",
          content: process.env.VUE_APP_HOST + this.$route.path,
          vmid: "og:url",
        },
      ],
      link: [
        { rel: "canonical", href: process.env.VUE_APP_HOST + this.$route.path },
      ],
    };
  },
  components: {
    "main-bar": MainBar,
    "mobile-bar": MobileMainBar,
    "session-expired": SessionExpiredDialog,
    "welcome-dialog": WelcomeDialog,
    "main-footer": MainFooter,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      status: (state) => state.account.status,
      session: (state) => state.account.session,
    }),
    show_welcome: function () {
      if (this.user && this.user.data && this.user.data.WelcomeDiaryModal) {
        this.stopTimer();
        return true;
      } else return false;
    },
  },
  data: () => ({
    is_loading: true,

    online_timer: null,
    show_login_dialog: false,
  }),
  mounted() {
    var self = this;
    this.$vuetify.lang.current = process.env.VUE_APP_LANG;

    self
      .init()
      .then(function () {
        self.is_loading = false;

        if (self.user) {
          self.startTimer();
        }
      })
      .catch(function () {
        self.is_loading = false;
      });
  },
  created() {},
  methods: {
    ...mapActions({
      init: "account/init",
      userStatusReloader: "account/userStatusReloader",
    }),
    startTimer() {
      var self = this;

      if (self.online_timer) clearTimeout(self.online_timer);

      self.online_timer = setTimeout(function () {
        clearTimeout(self.online_timer);

        self
          .userStatusReloader()
          .then(function () {
            self.startTimer();
          })
          .catch(function () {
            self.startTimer();
          });
      }, 120000);
    },
    stopTimer() {
      var self = this;

      if (self.online_timer) clearTimeout(self.online_timer);
    },
  },
  watch: {
    session: function () {
      if (this.session) {
        this.startTimer();
      } else {
        clearTimeout(this.online_timer);
      }
    },
    "status.is_expired": function () {
      clearTimeout(this.online_timer);
      this.show_login_dialog = true;
    },
  },
};
</script>

<style>
/*https://coolors.co/e63946-f1faee-a8dadc-457b9d-1d3557*/
/*https://fonts.google.com/?preview.text=Barndagboken&preview.text_type=custom*/
/*https://www.hexcolortool.com/#447a9c*/
.container {
  max-width: 1200px !important;
}

.logo-font {
  font-family: "Architects Daughter", cursive !important;
}
.logo-top,
.logo-top i.v-icon {
  font-size: 1.8rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
}

.main-h2 {
  font-size: 45px !important;
  color: #457b9d;
}

.active-menu {
  border-bottom: solid 2px #457b9d !important;
  color: #457b9d !important;
  background: none !important;
}

.pointer {
  cursor: pointer;
}
.default-bg {
  /*background-color: #EEF5F9;*/
  background-color: #f7f9fc;
}

.error-text {
  color: red;
}

.success-text {
  color: green;
  font-weight: bold;
}

.flexcard {
  display: flex;
  flex-direction: column;
}

.global-list-header {
  font-size: 15px !important;
  font-weight: 500;
  margin-bottom: 4px;
  color: black;
}
.global-list-desc {
  color: gray;
  font-size: 13px !important;
}
.global-list-item-active-darker {
  background-color: #ddebfd !important;
}
.global-list-item-active1 {
  background-color: #f0f6ff !important;
}
.global-list-item-active {
  background-color: #eaf3ff !important;
}
.global-list-item-active.theme--light.v-list-item--active::before {
  opacity: 0 !important;
}

/* Set max image with. Not outside container */
.image-width-fix img {
  max-width: 100% !important;
}

/* card text fix, not cut word */
.v-card__text,
.v-card__title {
  word-break: break-word; /* maybe !important  */
}

.text-fix {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.top-bar-dropdown .sub-header {
  padding-top: 2px !important;
  padding-bottom: 5px !important;
  font-weight: bold !important;
  height: auto !important;
}

.top-bar-dropdown .sub-header a {
  text-decoration: none;
  font-weight: normal !important;
}

.top-bar-dropdown .sub-header a:hover {
  text-decoration: underline;
}

.vts .v-snack__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-border-bottom-fix .v-slide-group__content {
  border-bottom: solid 1px #e3e3e3;
  border-top: none;
}
.tab-border-all-fix .v-slide-group__content {
  border-bottom: solid 1px #e3e3e3;
  border-top: solid 1px #e3e3e3;
}

.disabled {
  opacity: 0.5;
}

/* TIMELINE */
h2.timeline-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  font-family: "Roboto", sans-serif;
}
.timeline-header a {
  text-decoration: none;
  color: black !important;
  font-weight: 500;
}

.timeline-header a:hover {
  text-decoration: underline;
}

.timeline-item-header {
  background-color: #2c6284;
  color: white;
}
.timeline-subheader {
  font-weight: bold;
  margin-top: 10px;
}
.timeline-body {
  margin-top: 5px;
}

.diary-timeline .v-timeline-item__body > .v-card:not(.v-card--flat):after {
  border-right-color: #2c6284;
}
i.timeline-icon {
  font-size: 18px !important;
}
/* ---- TIMELINE ---- */

.read-toggle {
  color: #1976d2;
}

.link-color {
  color: #1976d2 !important;
  text-decoration: none;
  cursor: pointer;
}
.link-color:hover {
  text-decoration: underline;
}

.notification-list .v-list-item:hover {
  background-color: #f6f6f6 !important;
}
.notification-list .v-list-item.unreaded {
  background-color: #edf2fa;
}

/*dropdown menu */
.admin-list-menu .v-list-item__icon {
  margin-top: 11px;
  margin-right: 10px !important;
}
.admin-list-menu .v-list-item {
  min-height: 30px !important;
  height: 46px;
}

.badge-default .v-badge__badge {
  color: black;
}

@media screen and (max-width: 768px) {
  .mobile-tabs-fix .v-slide-group__prev {
    display: none !important;
  }
  .mobile-bar-tabs .v-slide-group__content {
    border-bottom: none !important;
    border-top: none !important;
  }
  .mobile-padding-fix {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mobile-padding-top-fix {
    padding-top: 0 !important;
  }
  .mobile-margin-fix {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .button-table button,
  .button-table a {
    margin-top: 7px;
  }
}

.admin-top-button {
  background-color: #f5f5f5 !important;
  border-color: lightgray;
}
</style>