import i18n from "@/plugins/i18n";
import { enums } from "../_helpers";

export const menuHelpers = {
  getAll,
  getAllWithCheck,
  getActiveMenu,
};

function getAll() {
  return [
    {
      key: "home",
      url: "diary_home",
      name: i18n.t("DiaryMenu.Home"),
      exact: true,
      can_change_order: false,
      can_inactivate: false,
      is_active: true,
      settings_menu: [{ name: i18n.t("Settings"), key: enums.DIARYSETTINGS.Feed }],
      settings: {}
    },
    {
      key: "diary",
      url: "diary_blog",
      name: i18n.t("DiaryMenu.Diary"),
      exact: false,
      can_change_order: true,
      can_inactivate: false,
      is_active: true,
      settings_menu: [
        { name: i18n.t("Settings"), key: enums.DIARYSETTINGS.BlogPage },
      ],
      settings: {}
    },
    {
      key: "children",
      url: "diary_children",
      name: i18n.t("DiaryMenu.Children"),
      exact: false,
      can_change_order: true,
      can_inactivate: false,
      is_active: true,
      settings_menu: [],
      settings: {}
    },
    {
      key: "album",
      url: "diary_albums",
      name: i18n.t("DiaryMenu.Album"),
      exact: false,
      can_change_order: true,
      is_active: true,
      settings_menu: [],
      settings: {}
    },
    {
      key: "guestbook",
      url: "diary_guestbook",
      name: i18n.t("DiaryMenu.Guestbook"),
      exact: false,
      can_change_order: true,
      is_active: true,
      settings_menu: [
        { name: i18n.t("Settings"), key: enums.DIARYSETTINGS.Guestbook },
      ],
      settings: {
        active_status: enums.DIARYSETTINGS.Guestbook
      }
    },
  ];
}

function getMenuItemsFromSettings(settings) {
  var items = ["home", "diary", "children", "album", "guestbook"];

  if (
    settings &&
    settings.MenuSettings &&
    settings.MenuSettings.items &&
    settings.MenuSettings.items.length > 0
  )
    return settings.MenuSettings.items;
  return items;
}

function getAllWithCheck(settings, visitorInfo, user) {
  var currentMenu = [];
  var selectedItems = getMenuItemsFromSettings(settings);
  var menuItems = getAll();

  for (let index = 0; index < selectedItems.length; index++) {
    const element = selectedItems[index];
    var item = menuItems.find((p) => p.key == element);

    item.is_active = IsActive(item.key, settings);
    item.has_access = hasAccess(item.key, settings, visitorInfo, user);

    if (!currentMenu.find((p) => p.key == item.key)) currentMenu.push(item);
  }

  return currentMenu;
}

function IsActive(key, settings) {
  if (!settings) return true;

  if (key == "guestbook") {
    if (
      settings.GuestbookSettings &&
      settings.GuestbookSettings.is_active == false
    )
      return false;
  }

  return true;
}

function hasAccess(key, settings, visitorInfo, user) {
  if (!settings) return true;

  if (key == "guestbook" && settings.GuestbookSettings) {
    var privacy = settings.GuestbookSettings.privacy;

    return hasPrivacyAccess(privacy, visitorInfo, user);
  }
  return true;
}

function hasPrivacyAccess(privacy, visitorInfo, user) {
  if (user && user.roles && user.roles.indexOf("Admin") > -1) return true;
  if (privacy == enums.DIARYPAGEPRIVACY.OpenForAll) return true;
  if (
    privacy == enums.DIARYPAGEPRIVACY.FamilyAndFriends &&
    visitorInfo &&
    visitorInfo.roles &&
    visitorInfo.roles.length > 0
  )
    return true;
  if (privacy == enums.DIARYPAGEPRIVACY.OnlyForMembers && user) return true;
  return false;
}

function getActiveMenu(settings, visitorInfo, user) {
  return getAllWithCheck(settings, visitorInfo, user).filter(
    (p) => p.is_active && p.has_access
  );
}
