import { apiClient } from "../../_helpers";

export const commentsService = {
  search,
  statistics,
  delete: _delete,
  approve,
  unspam,
  unabuse,
};

function search(diary, data) {
  return apiClient.get("/diary/" + diary + "/comments", data);
}
function statistics(diary) {
  return apiClient.get("/diary/" + diary + "/comments/statistics");
}
function approve(id) {
  return apiClient.put("/diary/comments/" + id + "/approve");
}
function unspam(id) {
  return apiClient.put("/diary/comments/" + id + "/unspam");
}
function unabuse(id) {
  return apiClient.put("/diary/comments/" + id + "/unabuse");
}
function _delete(id) {
  return apiClient.delete("/diary/comments/" + id);
}
