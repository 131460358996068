import { apiClient } from '../_helpers';

export const offersService = {
    categories,
    search
};

function categories() {
    return apiClient.get('/offers/categories');
}
function search(category, search, page) {
    return apiClient.get('/offers', {
        categoryId: category,
        q: search,
        page: page
    });
}