import { apiClient } from "../_helpers";

export const globalService = {
  contactSupport,
  sendFeedback,
  getNewsMedias,
  uploadNewsMedias,

  getInvite,
  acceptInvite
};

function contactSupport(data) {
  return apiClient.post("/page/contact/support", data);
}

function sendFeedback(subject, message) {
  return apiClient.post("/page/contact/feedback", { subject, message });
}

function getNewsMedias() {
  return apiClient.get("/system/media/news");
}

function uploadNewsMedias(file) {
  var formData = new FormData();
  formData.append("file", file);
  return apiClient.post("/system/media/news", formData);
}

function getInvite(key) {
  return apiClient.get("/diary/members/invite/" + key);
}
function acceptInvite(key) {
  return apiClient.post("/diary/members/invite/" + key);
}