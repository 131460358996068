import { render, staticRenderFns } from "./FeedbackDialog.vue?vue&type=template&id=09ef315f&scoped=true&"
import script from "./FeedbackDialog.vue?vue&type=script&lang=js&"
export * from "./FeedbackDialog.vue?vue&type=script&lang=js&"
import style0 from "./FeedbackDialog.vue?vue&type=style&index=0&id=09ef315f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09ef315f",
  null
  
)

/* custom blocks */
import block0 from "./FeedbackDialog.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VDivider,VForm,VIcon,VList,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VTextarea})
