export const imageHelpers = {
  diaryAvatar,
  childAvatar,
  accountAvatar,
  mediaThumb,
  getImageFormUrl,
  mediaWidth,
  diaryBanner,
};

function getAvatar(avatar, size) {
  if (avatar && avatar.original) {
    if (size < 51) {
      return avatar.thumb_50;
    } else if (size < 151) {
      return avatar.thumb_150;
    } else if (size < 201) {
      return avatar.thumb_200;
    } else if (size === 0 || size > 500) {
      return avatar.original;
    }
    return avatar.thumb_300;
  }
  return null;
}

function diaryBanner(image) {
  if (!image) return null;
  return image.width_1200;
}

function diaryAvatar(avatar, size) {
  const url = getAvatar(avatar, size);

  if (url) return url;

  return "https://cdn.babiry.com/web/avatar/nopic_192.gif";
}
function accountAvatar(avatar, size) {
  const url = getAvatar(avatar, size);

  if (url) return url;

  return "https://cdn.babiry.com/web/avatar/empty-avatar.jpg";
}
function childAvatar(avatar, size) {
  const url = getAvatar(avatar, size);

  if (url) return url;

  //if (sexType === enums.SEXTYPES.BOY) {

  //}
  //if (sexType === enums.SEXTYPES.GIRL) {

  //}

  return "https://cdn.babiry.com/web/avatar/child_avatar_100.jpg";
}
function mediaThumb(mediaObj, size) {
  const url = getAvatar(mediaObj, size);

  if (url) return url;

  return "https://cdn.babiry.com/web/avatar/empty.jpg";
}

function mediaWidth(mediaObj, size) {
  if (mediaObj && mediaObj.original) {
    if (size === 400) {
      return mediaObj.width_400;
    } else if (size === 800) {
      return mediaObj.width_800;
    }
    return mediaObj.original;
  }

  return "https://cdn.babiry.com/web/avatar/empty.jpg";
}

function getImageFormUrl(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = function() {
      var canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0);

      var dataURI = canvas.toDataURL("image/jpg");

      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      resolve(new Blob([ia], { type: mimeString }));
    };

    img.onerror = function(error) {
      reject(error);
    };

    img.src = url;
  });
}
