import { apiClient } from "../_helpers";

export const diaryService = {
  load,
  authorize,
  uploadAvatar,
  deleteAvatar,
  uploadBanner,
  deleteBanner,
  updateGeneralSettings,
  updatePrivacy,
  loadAdminDiary,
  updateAbout,
  login,
  updateBackgroundStyle,
  follow,
  unfollow,
  followers,
  searchInDiary,
  saveStyles,
  search,
  createDiary,
  updateSettings,
};

function load(name) {
  return apiClient.get("/diary/" + name);
}

function login(name, password) {
  return apiClient.post("/diary/" + name + "/login", { password });
}

function authorize(name) {
  return apiClient.get("/diary/" + name + "/authorize");
}

function uploadAvatar(name, imageFile, imageId) {
  const formData = new FormData();
  formData.append("avatar", imageFile);

  if (imageId) formData.append("image_id", imageId);

  return apiClient.post("/diary/" + name + "/avatar", formData);
}

function deleteAvatar(name) {
  return apiClient.delete("/diary/" + name + "/avatar");
}

function uploadBanner(name, imageFile, imageId) {
  const formData = new FormData();
  formData.append("banner", imageFile);

  if (imageId) formData.append("image_id", imageId);

  return apiClient.post("/diary/" + name + "/style/banner", formData);
}

function deleteBanner(name) {
  return apiClient.delete("/diary/" + name + "/style/banner");
}

function updateGeneralSettings(name, data) {
  return apiClient.patch("/diary/" + name, data);
}
function updateAbout(name, data) {
  return apiClient.patch("/diary/" + name + "/about", data);
}

function updatePrivacy(name, data) {
  return apiClient.put("/diary/" + name + "/privacy", data);
}

function loadAdminDiary(name) {
  return apiClient.get("/diary/" + name + "/admin");
}

function updateBackgroundStyle(name, { data, file, image_id }) {
  const formData = new FormData();
  formData.append("background_image", file);
  formData.append("background_color", data.background_color);
  formData.append("background_image_repeat", data.background_image_repeat);
  formData.append("delete_background_image", data.background_image == null);
  if (image_id) formData.append("image_id", image_id);

  return apiClient.post("/diary/" + name + "/style/background", formData);
}

function saveStyles(name, data) {
  return apiClient.post("/diary/" + name + "/style", data);
}

function follow(name) {
  return apiClient.put("/diary/" + name + "/followers");
}

function unfollow(name) {
  return apiClient.delete("/diary/" + name + "/followers");
}

function followers(name, page) {
  return apiClient.get("/diary/" + name + "/followers?page=" + page);
}
function searchInDiary(name, q) {
  return apiClient.get("/diary/" + name + "/search?q=" + encodeURIComponent(q));
}

function search(page, pagesize) {
  return apiClient.get("/diary/search", { page, pagesize });
}

function createDiary(diary) {
  return apiClient.post("/diary", diary);
}

function updateSettings(diary, name, data) {
  return apiClient.patch("/diary/" + diary + "/settings/" + name, data);
}
