export const blobRequest = {
  getText,
};

function getText(url) {
  return new Promise((resolve, reject) => {
    fetch(process.env.VUE_APP_TEXT_FOLDER_URL + url)
      .then((response) => response.text())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
