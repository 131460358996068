import axios from "axios";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  //baseURL: 'http://localhost:61918/api',

  headers: { "X-API-Key": process.env.VUE_APP_API_KEY },
});

export const apiClient = {
  post,
  get,
  put,
  patch,
  delete: _delete,
  getToken,
};

function post(url, data, config) {
  client.defaults.headers.common["Authorization"] = getToken();
  client.defaults.headers.common["X-DiaryToken"] = getDiaryToken();

  return client.post(url, data, config);
}
function get(url, data, config) {
  client.defaults.headers.common["Authorization"] = getToken();
  client.defaults.headers.common["X-DiaryToken"] = getDiaryToken();

  return client.get(
    url,
    {
      params: data,
    },
    config
  );
}
function put(url, data, config) {
  client.defaults.headers.common["Authorization"] = getToken();
  client.defaults.headers.common["X-DiaryToken"] = getDiaryToken();

  return client.put(url, data, config);
}
function patch(url, data, config) {
  client.defaults.headers.common["Authorization"] = getToken();
  client.defaults.headers.common["X-DiaryToken"] = getDiaryToken();

  return client.patch(url, data, config);
}
function _delete(url, data, config) {
  client.defaults.headers.common["Authorization"] = getToken();
  client.defaults.headers.common["X-DiaryToken"] = getDiaryToken();

  return client.delete(url, data, config);
}

function getToken() {
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return "Bearer " + user.token;
  } else {
    return "";
  }
}

function getDiaryToken() {
  let data = JSON.parse(sessionStorage.getItem("diary_token"));

  if (data && data.token) {
    return data.token;
  } else {
    return "";
  }
}
