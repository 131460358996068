import { apiClient } from '../../_helpers';

export const mediacommentsService = {
    delete: _delete,
    create,
    load,
    answer,
    abuse,
    getPost,
    deleteAnswer,
    getPendingComments,
    approvePost,
    getStatistics,
    getAllPosts,
    getAcceptedPosts,
    getAbusedPosts,
    getSpamPosts
};

function load(mediaId, page) {
    return apiClient.get('/diary/media/' + mediaId + '/comments?page=' + page);
}
function _delete(id) {
    return apiClient.delete('/diary/media/comments/' + id);
}
function getPost(id) {
    return apiClient.get('/diary/media/comments/' + id);
}
function create(mediaId, data) {
    return apiClient.post('/diary/media/' + mediaId + '/comments', data);
}
function answer(id, data) {
    return apiClient.post('/diary/media/comments/' + id, data);
}
function abuse(id, text) {
    return apiClient.post('/diary/media/comments/' + id + '/abuse', { message: text });
}
function deleteAnswer(threadId, answerId) {
    return apiClient.delete('/diary/media/comments/' + threadId + '/answer/' + answerId);
}
function getPendingComments(diary, page) {
    return apiClient.get('/diary/' + diary + '/media/comments/pending?page=' + page);
}
function getAllPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/media/comments/all?page=' + page);
}
function getAcceptedPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/media/comments/accepted?page=' + page);
}
function getAbusedPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/media/comments/abused?page=' + page);
}
function getSpamPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/media/comments/spam?page=' + page);
}
function getStatistics(diary) {
    return apiClient.get('/diary/' + diary + '/media/comments/statistics');
}
function approvePost(id) {
    return apiClient.put('/diary/media/comments/' + id + '/approve');
}