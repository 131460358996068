import { apiClient } from '../../_helpers';

export const guestbookService = {
    load,
    getPost,
    create,
    answer,
    abuse,
    approvePost,
    getPendingPosts,
    delete: _delete,
    deleteAnswer,
    getStatistics,
    getAllPosts,
    getAcceptedPosts,
    getAbusedPosts,
    getSpamPosts
};

function load(diary, page) {
    return apiClient.get('/diary/' + diary + '/guestbook?page=' + page);
}
function getPost(id) {
    return apiClient.get('/diary/guestbook/' + id);
}
function create(diary, data) {
    return apiClient.post('/diary/' + diary + '/guestbook', data);
}
function answer(id, data) {
    return apiClient.post('/diary/guestbook/' + id, data);
}
function abuse(id, text) {
    return apiClient.post('/diary/guestbook/' + id + '/abuse', { message: text });
}

function getPendingPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/guestbook/pending?page=' + page);
}
function getAllPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/guestbook/all?page=' + page);
}
function getAcceptedPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/guestbook/accepted?page=' + page);
}
function getAbusedPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/guestbook/abused?page=' + page);
}
function getSpamPosts(diary, page) {
    return apiClient.get('/diary/' + diary + '/guestbook/spam?page=' + page);
}
function getStatistics(diary) {
    return apiClient.get('/diary/' + diary + '/guestbook/statistics');
}
function approvePost(id) {
    return apiClient.put('/diary/guestbook/' + id + '/approve');
}
function _delete(id) {
    return apiClient.delete('/diary/guestbook/' + id);
}
function deleteAnswer(threadId, answerId) {
    return apiClient.delete('/diary/guestbook/' + threadId + '/answer/'+ answerId);
}