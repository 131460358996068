import { apiClient } from "../../_helpers";

export const childrenService = {
  getChild,
  getChildByUrlName,
  createChild,
  updateChild,
  deleteChild,
  getProfile,
  getFeed,
  getSizes,
  deleteSize,
  updateSize,
  createSize,

  getEvents,
  deleteEvent,
  createEvent,
  updateEvent,
  getSizeGraph,
  updateChildProfile,
  uploadAvatar,
  deleteAvatar,

  getBlogPostsToChild,
  getPhotosToChild,

  updateChildrenOrder,

  getMemory,
  deleteMemory,
  createMemory,
  updateMemory,
  addMemoryCover,
  getMemoryById
};

function createChild(diaryName, child, imageFile) {
  if (!child.description) child.description = "";

  const formData = new FormData();
  formData.append("avatar", imageFile);
  formData.append("name", child.name);
  formData.append("birth", child.birth);
  formData.append("description", child.description);

  return apiClient.post("/diary/" + diaryName + "/child", formData);
}

function uploadAvatar(id, imageFile) {
  var formData = new FormData();
  formData.append("avatar", imageFile);

  return apiClient.post("/diary/child/" + id + "/avatar", formData);
}

function deleteAvatar(id) {
  return apiClient.delete("/diary/child/" + id + "/avatar");
}

function updateChild(id, child) {
  if (!child.description) child.description = "";

  var formData = new FormData();
  formData.append("name", child.name);
  formData.append("birth", child.birth);
  formData.append("description", child.description);

  return apiClient.post("/diary/child/" + id, formData);
}

function deleteChild(id) {
  return apiClient.delete("/diary/child/" + id);
}

function getChild(id) {
  return apiClient.get("/diary/child/" + id);
}
function getChildByUrlName(diary, urlName) {
  return apiClient.get("/diary/" + diary + "/child/" + urlName);
}
function getProfile(diary, child) {
  return apiClient.get("/diary/" + diary + "/child/" + child + "/profile");
}
function getFeed(id, page) {
  return apiClient.get("/diary/child/" + id + "/feed?page=" + page);
}

function getSizes(child) {
  return apiClient.get("/diary/child/" + child + "/size");
}
function deleteSize(id) {
  return apiClient.delete("/diary/child/size/" + id);
}
function createSize(child, data) {
  return apiClient.post("/diary/child/" + child + "/size", data);
}
function updateSize(id, data) {
  return apiClient.put("/diary/child/size/" + id, data);
}
function getSizeGraph(id, type) {
  return apiClient.get("/diary/child/" + id + "/size/chart/" + type);
}

function getEvents(id) {
  return apiClient.get("/diary/child/" + id + "/events");
}
function deleteEvent(id) {
  return apiClient.delete("/diary/child/event/" + id);
}
function createEvent(child, data) {
  return apiClient.post("/diary/child/" + child + "/event", data);
}
function updateEvent(id, data) {
  return apiClient.put("/diary/child/event/" + id, data);
}
function updateChildProfile(childId, data) {
  return apiClient.post("/diary/child/" + childId + "/profile", data);
}

function getBlogPostsToChild(childId, page) {
  return apiClient.get("/diary/child/" + childId + "/posts?page=" + page);
}
function getPhotosToChild(childId, page) {
  return apiClient.get("/diary/child/" + childId + "/media?page=" + page);
}
function updateChildrenOrder(diary, childrenId) {
  return apiClient.post("/diary/" + diary + "/children/order", childrenId);
}

function getMemoryById(id) {
  return apiClient.get("/diary/child/memories/" + id);
}
function getMemory(childId, urlName) {
  return apiClient.get("/diary/child/" + childId + "/memories/" + urlName);
}
function deleteMemory(id) {
  return apiClient.delete("/diary/child/memories/" + id);
}
function createMemory(childId, key, data) {
  return apiClient.post(
    "/diary/child/" + childId + "/memories/" + key,
    data
  );
}
function updateMemory(id, data) {
  return apiClient.patch(
    "/diary/child/memories/" + id,
    data
  );
}
function addMemoryCover(id, mediaid) {
  return apiClient.put("/diary/child/memories/" + id + "/media/" + mediaid);
}
