import * as moment from "moment";
import i18n from "@/plugins/i18n";

export const dateHelpers = {
  timeSince,
  getFormatedChildAge,
  getDateDiffFromDateObject,
  getFormatedTimelineDate,
  getFormatedDateFromDatetime,
  getDateDiffObject,
  getFormatedChildAgeFromDate,
};

function getFormatedDateFromDatetime(date) {
  return moment(date).format("YYYY-MM-DD");
}

function getFormatedTimelineDate(date) {
  const start = moment(date, "YYYY-MM-DD");
  const today = moment().utc();
  const days = today.diff(start, "days");

  if (days === 0) return i18n.t("Today");
  else if (days === 1) return i18n.t("Yesterday");
  else {
    if (start.year() === today.year()) return start.format("D MMM");
    else return start.format("D MMM YYYY");
  }
}

function timeSince(utcDate) {
  return moment.utc(utcDate).fromNow();
}

function getFormatedChildAge(birth) {
  return getFormatedChildAgeFromDate(birth, new Date());
}

function getFormatedChildAgeFromDate(birth, currentDate) {
  if (!birth) return "";

  const age = getDateDiffFromDateObject(birth, currentDate);

  if (age.years >= 3) {
    return age.years + " " + i18n.t("YearsOld");
  }
  if (age.years >= 1) {
    if (age.months > 0)
      return (
        age.years +
        " " +
        formatUnitText(age.years, i18n.t("YearAnd"), i18n.t("YearsAnd")) +
        " " +
        age.months +
        " " +
        formatUnitText(age.months, i18n.t("MonthOld"), i18n.t("MonthsOld"))
      );
    else return age.years + " " + formatUnitText(age.years, i18n.t("Year"), i18n.t("Years"))
  }
  if (age.months >= 1) {
    return age.months + " " + formatUnitText(age.months, i18n.t("MonthOld"), i18n.t("MonthsOld"));
  }
  if (age.weeks >= 1) {
    return age.weeks + " " + formatUnitText(age.weeks, i18n.t("WeekOld"), i18n.t("WeeksOld"));
  }
  if (age.days > 1) {
    return age.days + " " + i18n.t("DaysOld");
  }
  return i18n.t("Newborn");
}


function formatUnitText(unit, one, multi) {
  if(unit > 1) return multi;
  return one;
}


function getDateDiffObject(birth) {
  var b = moment(birth),
    a = moment(new Date()),
    intervals = ["years", "months", "weeks", "days"];

  var age = { years: 0, months: 0, weeks: 0, days: 0 };

  intervals.forEach(function(interval) {
    const diff = a.diff(b, interval);
    b.add(diff, interval);

    if (interval === "years") {
      age.years = diff;
    } else if (interval === "months") {
      age.months = diff;
    } else if (interval === "weeks") {
      age.weeks = diff;
    } else if (interval === "days") {
      age.days = diff;
    }
  });
  return age;
}

function getDateDiffFromDateObject(birth, currentDate) {
  var b = moment(birth),
    a = moment(currentDate),
    intervals = ["years", "months", "weeks", "days"];

  var age = { years: 0, months: 0, weeks: 0, days: 0 };

  intervals.forEach(function(interval) {
    const diff = a.diff(b, interval);
    b.add(diff, interval);

    if (interval === "years") {
      age.years = diff;
    } else if (interval === "months") {
      age.months = diff;
    } else if (interval === "weeks") {
      age.weeks = diff;
    } else if (interval === "days") {
      age.days = diff;
    }
  });
  return age;
}
