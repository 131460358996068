import { apiClient } from "../_helpers";

export const systemPagesService = {
  getPage,
  create,
  update,
  getDetails,
  getPages
};

function getPages() {
  return apiClient.get("/pages/all");
}
function getPage(name) {
  return apiClient.get("/pages/" + name);
}
function create(data) {
  return apiClient.post("/pages", data);
}
function update(id, data) {
  return apiClient.post("/pages/" + id, data);
}
function getDetails(id) {
  return apiClient.get("/pages/" + id + "/details");
}
