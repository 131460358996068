import i18n from "@/plugins/i18n";

export const responseHelpers = {
  errorTextFromResponse,
  getErrorResponseCode,
};

function errorTextFromResponse(data) {
  if (data.response) {
    if (data.response.status === 401) {
      return i18n.t("Error.UnAuthorized");
    }
    if (data.response.data.message) {
      return data.response.data.message;
    }
    if (
      data.response.status === 404 ||
      data.response.status === 405 ||
      data.response.status === 415
    ) {
      return i18n.t("Error.RequestNotFound");
    }

    return data.response.data;
  }

  return data;
}

function getErrorResponseCode(data) {
  if (data.response) {
    if (data.response.data && data.response.data.status_code) {
      return data.response.data.status_code;
    }
    if (data.response.status) {
      return data.response.status;
    }

    return null;
  }

  return data;
}
