import { apiClient } from '../_helpers';

export const accountService = {
    login,
    logout,
    register,
    currentUser,
    forgotPassword,
    resetPassword,
    saveWelcomeInfo,
    closeWelcomeInfo,
    oauthConnect,
    resendVerificationMail,
    updateProfile,
    uploadAvatar,
    deleteAvatar,
    changePassword,
    getProfile,
    deleteSocialConnection,
    addSocialConnection,
    getUserProfile,
    updateEmail,
    confirmEmail,
    getFollowing,
    getMyFollowing,
    registerSimple,
    currentUserStatus
};

function login(username, password) {
    return apiClient.post('/user/login', {
        username,
        password
    });
}

function logout() {
}

function register(user) {
    return apiClient.post('/user', {
        username: user.username,
        password: user.password,
        email: user.email
    });
}

function registerSimple(user) {
    return apiClient.post('/user/account', {
        username: user.username,
        password: user.password,
        email: user.email
    });
}

function currentUser() {
    return apiClient.get('/user');
}

function currentUserStatus() {
    return apiClient.get('/user/status');
}

function forgotPassword(email) {
    return apiClient.post('/user/forgotpassword', { email });
}
function resetPassword(data) {
    return apiClient.post('/user/resetpassword', data);
}
function confirmEmail(user, key) {
    return apiClient.get('/user/verifyemail?user=' + user + "&key=" + key);
}

function updateEmail(data) {
    return apiClient.post('/user/email', data);
}

function saveWelcomeInfo(user) {
    return apiClient.post('/user/welcomesettings', user);
}

function oauthConnect(type, token) {
    return apiClient.post('/oauth', {
        type: type,
        token: token
    });
}

function closeWelcomeInfo() {
    return apiClient.delete('/user/welcomesettings');
}

function resendVerificationMail() {
    return apiClient.get('/user/verifyemail');
}

function updateProfile(data) {
    return apiClient.post('/user/profile', data);
}

function getProfile() {
    return apiClient.get('/user/profile');
}
function getUserProfile(username) {
    return apiClient.get('/user/' + username + '/profile');
}

function deleteSocialConnection(id) {
    return apiClient.delete('/user/socialconnect/' + id);
}
function addSocialConnection(data) {
    return apiClient.post('/user/socialconnect', data);
}

function uploadAvatar(imageFile) {
    var formData = new FormData();
    formData.append("avatar", imageFile);

    return apiClient.post('/user/me/avatar', formData);
}

function deleteAvatar() {
    return apiClient.delete('/user/me/avatar');
}

function changePassword(data) {
    return apiClient.post('/user/password', data);
}

function getFollowing(username, page) {
    return apiClient.get('/user/' + username + '/following?page=' + page);
}
function getMyFollowing(page) {
    return apiClient.get('/user/following?page=' + page);
}