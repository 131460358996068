import { enums } from '../_helpers';
import * as moment from 'moment'
import i18n from "@/plugins/i18n";

export const notificationCreator = {
    formatedNotifications
};

function formatedNotifications(items) {
    if (!items) return null;

    const unit = items.length;

    for (var i = 0; i < unit; i++) {
        const item = items[i];
        item.text = getText(item);
        item.url = getUrl(item);
        item.icon = getIcon(item);
        item.time_ago = moment(item.date).fromNow();
    }

    return items;
}

function getText(item) {
    var data = item.data;
    var blogTextLength = 30;

    if(item.type == enums.NOTIFICATIONTYPES.AcceptedInvite) {
        return "<span class='user'>" + data.nickname + "</span> " + i18n.t("Notifications.AcceptedBlogInvite") + " <span class='blog'>" + textLength(data.blog.title, blogTextLength) + "</span>.";
    }

    if (item.type === enums.NOTIFICATIONTYPES.GuestbookComment) {
        if(data.user.length > 10) {
            return i18n.t("Notifications.GuestbookComment10");
        }
        else if(data.user.length > 2) {
            var other = data.user.length - 2;
            return getUsers(data.user.slice(0, 2)) + " " + i18n.t("Notifications.GuestbookCommentOther").replace("[?]", other);
        }
        else {
            return getUsers(data.user) + " " + i18n.t("Notifications.GuestbookComment");
        }
    }
    else if (item.type === enums.NOTIFICATIONTYPES.AnswerGuestbookComment) {
        return getUsers(data.user) + " " + i18n.t("Notifications.AnswerGuestbookComment");
    }
    else if (item.type === enums.NOTIFICATIONTYPES.GuestbookCommentToApprove) {
        return i18n.t("Notifications.GuestbookCommentToApprove");
    }
    else if (item.type === enums.NOTIFICATIONTYPES.GuestbookCommentApproved) {
        return i18n.t("Notifications.GuestbookCommentApproved") + " <span class='blog'>" + textLength(data.blog.title, blogTextLength) + "</span>.";
    }


    else if (item.type === enums.NOTIFICATIONTYPES.BlogPostComment) {
        if(data.user.length > 10) {
            return i18n.t("Notifications.BlogPostComment10") + " <span class='post'>" + textLength(data.post.header, 30) + "</span>.";
        }
        else if(data.user.length > 2) {
            var other2 = data.user.length - 2;
            return getUsers(data.user.slice(0, 2)) + " " + i18n.t("Notifications.BlogPostCommentOther").replace("[?]", other2) + " <span class='post'>" + textLength(data.post.header, 30) + "</span>.";
        }
        else {
            return  getUsers(data.user) + " " + i18n.t("Notifications.BlogPostComment") + " <span class='post'>" + textLength(data.post.header, 30) + "</span>.";
        }
    }
    else if (item.type === enums.NOTIFICATIONTYPES.AnswerBlogPostComment) {
        return getUsers(data.user) + " " + i18n.t("Notifications.AnswerBlogPostComment") + " <span class='post'>" + textLength(data.post.header, 30) + "</span>.";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.BlogPostCommentToApprove) {
        return i18n.t("Notifications.BlogPostCommentToApprove");
    }
    else if (item.type === enums.NOTIFICATIONTYPES.BlogPostCommentApproved) {
        return i18n.t("Notifications.BlogPostCommentApproved") + " <span class='post'>" + textLength(data.post.header, 30) + "</span>.";
    }


    else if (item.type === enums.NOTIFICATIONTYPES.MediaComment) {
        if(data.user.length > 10) {
            return i18n.t("Notifications.MediaComment10") + " <span class='media'>" + textLength(data.media.header, 30) + "</span>.";
        }
        else if(data.user.length > 2) {
            var other3 = data.user.length - 2;
            return  getUsers(data.user.slice(0, 2)) + " " + i18n.t("Notifications.MediaCommentOther").replace("[?]", other3) + " <span class='media'>" + textLength(data.media.header, 30) + "</span>.";
        }
        else {
            return  getUsers(data.user) + " " + i18n.t("Notifications.MediaComment") + " <span class='media'>" + textLength(data.media.header, 30) + "</span>.";
        }
    }
    else if (item.type === enums.NOTIFICATIONTYPES.AnswerMediaComment) {
        return getUsers(data.user) + " " + i18n.t("Notifications.AnswerMediaComment") + " <span class='media'>" + textLength(data.media.header, 30) + "</span>.";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.MediaCommentToApprove) {
        return i18n.t("Notifications.MediaCommentToApprove");
    }
    else if (item.type === enums.NOTIFICATIONTYPES.MediaCommentApproved) {
        return i18n.t("Notifications.MediaCommentApproved") + " <span class='media'>" + textLength(data.media.header, 30) + "</span>.";
    }


    else if (item.type === enums.NOTIFICATIONTYPES.VideoUploaded) {
        return i18n.t("Notifications.VideoUploaded");
    }
    else if (item.type === enums.NOTIFICATIONTYPES.VideoUploadFailed) {
        return i18n.t("Notifications.VideoUploadFailed");
    }

    return i18n.t("Notifications.Default");
}

function getUsers(users) {
    var result = "";

    users.forEach(user => {
        if(result != "") result+=", ";
        result += getUserText(user);
    });
    return result;
}

function getUserText(user) {
    return "<span class='user'>"+textLength(user.username, 20)+"</span>";
}


function getUrl(item) {
    var data = item.data;

    if(item.type == enums.NOTIFICATIONTYPES.AcceptedInvite) {
        return "/" + data.blog.name + "/admin/members";
    }

    if (item.type === enums.NOTIFICATIONTYPES.GuestbookComment || item.type === enums.NOTIFICATIONTYPES.AnswerGuestbookComment || item.type === enums.NOTIFICATIONTYPES.GuestbookCommentApproved) {
        if(data.thread_id)
            return "/" + data.blog.name + "/guestbook/" + data.thread_id;
        else
            return "/" + data.blog.name + "/guestbook";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.GuestbookCommentToApprove) {
        return "/" + data.blog.name + "/admin/comments/pending";
    }



    else if (item.type === enums.NOTIFICATIONTYPES.BlogPostComment || item.type === enums.NOTIFICATIONTYPES.AnswerBlogPostComment || item.type === enums.NOTIFICATIONTYPES.BlogPostCommentApproved) {
        if(data.thread_id)
            return "/" + data.blog.name + "/diary/" + data.post.url_name + "/comment/" + data.thread_id;
        else
            return "/" + data.blog.name + "/diary/" + data.post.url_name;
    }
    else if (item.type === enums.NOTIFICATIONTYPES.BlogPostCommentToApprove) {
        return "/" + data.blog.name + "/admin/comments/pending";
    }


    else if (item.type === enums.NOTIFICATIONTYPES.MediaComment || item.type === enums.NOTIFICATIONTYPES.AnswerMediaComment || item.type === enums.NOTIFICATIONTYPES.MediaCommentApproved) {
        if(data.thread_id)
            return "/" + data.blog.name + "/album/media/" + data.media.id + "/comment/" + data.thread_id;
        else
            return "/" + data.blog.name + "/album/media/" + data.media.id;
    }
    else if (item.type === enums.NOTIFICATIONTYPES.MediaCommentToApprove) {
        return "/" + data.blog.name + "/admin/admin/comments/pending";
    }


    else if (item.type === enums.NOTIFICATIONTYPES.VideoUploaded) {
        return "/" + data.blog.name + "/album/media/" + data.media.id;
    }
    else if (item.type === enums.NOTIFICATIONTYPES.VideoUploadFailed) {
        return "";
    }
    return "";
}

function getIcon(item) {
    if(item.type == enums.NOTIFICATIONTYPES.AcceptedInvite) {
        return "$vuetify.icons.fasHeart";
    }


    if (item.type === enums.NOTIFICATIONTYPES.GuestbookComment) {
        return "$vuetify.icons.farComment";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.AnswerGuestbookComment) {
        return "$vuetify.icons.farComments";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.GuestbookCommentToApprove) {
        return "$vuetify.icons.farCommentDots";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.GuestbookCommentApproved) {
        return "$vuetify.icons.fasCommentMedical";
    }


    else if (item.type === enums.NOTIFICATIONTYPES.BlogPostComment) {
        return "$vuetify.icons.farComment";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.AnswerBlogPostComment) {
        return "$vuetify.icons.farComments";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.BlogPostCommentToApprove) {
        return "$vuetify.icons.farCommentDots";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.BlogPostCommentApproved) {
        return "$vuetify.icons.fasCommentMedical";
    }


    else if (item.type === enums.NOTIFICATIONTYPES.MediaComment) {
        return "$vuetify.icons.farComment";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.AnswerMediaComment) {
        return "$vuetify.icons.farComments";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.MediaCommentToApprove) {
        return "$vuetify.icons.farCommentDots";
    }
    else if (item.type === enums.NOTIFICATIONTYPES.MediaCommentApproved) {
        return "$vuetify.icons.fasCommentMedical";
    }

    return "$vuetify.icons.fasStar";
}


function textLength(txt, length) {
    if(!txt) return "";

    return txt.length > length ? txt.substring(0, length) + "..." : txt;
}