import { apiClient } from '../_helpers';

export const newsService = {
    categories,
    news,
    newsList,
    newsInCategory,
    getDetails,
    create,
    update,
    createCategory,
    updateCategory,
    getNewsItem,
    newsCards
};

function categories() {
    return apiClient.get('/page/news/categories');
}
function news(page) {
    return apiClient.get('/page/news', {
        page: page
    });
}
function newsCards(page, pagesize) {
    return apiClient.get('/page/news/cards', {
        page: page,
        pagesize: pagesize
    });
}
function newsList(page) {
    return apiClient.get('/page/news/all', {
        page: page
    });
}
function newsInCategory(category, page) {
    return apiClient.get('/page/news/category/' + category, {
        page: page
    });
}
function getDetails(id) {
    return apiClient.get("/page/news/" + id + "/details");
  }
  function getNewsItem(name) {
    return apiClient.get("/page/news/" + name);
  }
  
function create(data) {
    return apiClient.post('/page/news', data);
}
function update(id, data) {
    return apiClient.post('/page/news/' + id, data);
}
function createCategory(data) {
    return apiClient.post('/page/news/categories', data);
}
function updateCategory(id, data) {
    return apiClient.post('/page/news/categories/' + id, data);
}