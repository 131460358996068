import { apiClient } from "../../_helpers";

export const membersService = {
  members,
  invites,
  sendInvite,
  updateInvite,
  removeInvite,
  updateMember,
  deleteMember,
};

function members(name) {
  return apiClient.get("/diary/" + name + "/members");
}
function invites(name) {
  return apiClient.get("/diary/" + name + "/members/invites");
}
function sendInvite(name, data) {
  return apiClient.post("/diary/" + name + "/members/invite", data);
}
function updateMember(name, user, data) {
  return apiClient.put("/diary/" + name + "/members/" + user, data);
}
function deleteMember(name, user) {
  return apiClient.delete("/diary/" + name + "/members/" + user);
}
function updateInvite(id, data) {
  return apiClient.put("/diary/members/invite/" + id, data);
}
function removeInvite(id) {
    return apiClient.delete("/diary/members/invite/" + id);
  }