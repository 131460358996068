import { apiClient } from "../../_helpers";

export const timelineService = {
  delete: _delete,
  load,
  create,
  abuse,
};

function load(diary, page, type) {
  return apiClient.get(
    "/diary/" + diary + "/feed/?page=" + page + "&type=" + type
  );
}
function create(diary, item) {
  return apiClient.post("/diary/" + diary + "/feed", item);
}
function _delete(id) {
  return apiClient.delete("/diary/feed/" + id);
}
function abuse(id) {
  return apiClient.post("/diary/feed/" + id + "/abuse", { message: "null" });
}

