import { apiClient } from '../../_helpers';

export const categoriesService = {
    create,
    load,
    delete: _delete,
    update,
    get,
    getByName,
    loadAll
};

function create(name, data) {
    return apiClient.post('/diary/' + name + '/categories', data);
}
function load(name) {
    return apiClient.get('/diary/' + name + '/categories');
}
function loadAll(name) {
    return apiClient.get('/diary/' + name + '/categories/all');
}

function update(id, data) {
    return apiClient.put('/diary/categories/' + id, data);
}
function _delete(id) {
    return apiClient.delete('/diary/categories/' + id);
}
function get(id) {
    return apiClient.get('/diary/categories/' + id);
}
function getByName(diary, catName) {
    return apiClient.get('/diary/' + diary + '/categories/' + catName);
}