import { apiClient } from '../../_helpers';

export const blogBlockService = {
    delete: _delete,
    load,
    add,
    deleteUser
};

function load(diary, page) {
    return apiClient.get('/diary/' + diary + '/block/?page=' + page);
}
function add(diary, username) {
    return apiClient.get('/diary/' + diary + '/block/' + username);
}
function _delete(id) {
    return apiClient.delete('/diary/block/' + id);
}
function deleteUser(diary, username) {
    return apiClient.delete('/diary/' + diary + '/block/' + username);
}
