import { apiClient } from "../../_helpers";

export const blogPostService = {
  load,
  create,
  update,
  delete: _delete,
  getPostToForm,
  abuse,
  getPost,
  getPostByName,
  getPostsInCategory,
  getPostsWithPendingComments,
  searchPostList,
  updatePostPrivacy,
  updatePostCategories,
  updatePostChildren,
  updatePostDate,
  updateCover,
  publishstatistics
};


function load(diaryName, page) {
  return apiClient.get("/diary/" + diaryName + "/posts?page=" + page);
}
function getPostsInCategory(diaryName, categoryName, page) {
  return apiClient.get(
    "/diary/" + diaryName + "/category/" + categoryName + "/posts?page=" + page
  );
}
function publishstatistics(diaryName) {
  return apiClient.get("/diary/" + diaryName + "/posts/publish_statistics");
}
function create(diaryName, post) {
  post.is_publish = true;
  return apiClient.post("/diary/" + diaryName + "/posts", post);
}
function update(id, post) {
  return apiClient.put("/diary/posts/" + id, post);
}
function _delete(id) {
  return apiClient.delete("/diary/posts/" + id);
}
function getPostToForm(diaryName, id) {
  return apiClient.get("/diary/" + diaryName + "/blogpostform/" + id);
}
function abuse(id, text) {
  return apiClient.post("/diary/posts/" + id + "/abuse", { message: text });
}
function getPost(id) {
  return apiClient.get("/diary/posts/" + id);
}
function getPostByName(diary, postName) {
  return apiClient.get("/diary/" + diary + "/posts/" + postName);
}
function getPostsWithPendingComments(diary, page) {
  return apiClient.get(
    "/diary/" + diary + "/posts/comments/pending?page=" + page
  );
}
function searchPostList(diary, params) {
  return apiClient.get("/diary/" + diary + "/posts/list", params);
}
function updatePostPrivacy(id, privacy) {
  return apiClient.put("/diary/posts/" + id + "/privacy", { privacy: privacy });
}
function updatePostCategories(id, category_ids) {
  return apiClient.put("/diary/posts/" + id + "/categories", {
    ids: category_ids,
  });
}
function updateCover(id, images) {
  return apiClient.put("/diary/posts/" + id + "/cover", {
    ids: images,
  });
}
function updatePostChildren(id, children_ids) {
  return apiClient.put("/diary/posts/" + id + "/children", {
    ids: children_ids,
  });
}
function updatePostDate(id, date) {
  return apiClient.put("/diary/posts/" + id + "/publish_date", {
    date: date,
  });
}