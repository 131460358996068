import { apiClient } from "../../_helpers";

export const albumsService = {
  delete: _delete,
  load,
  create,
  update,
  loadAlbum,
  uploadImage,
  deleteMedia,
  updateMedia,
  mediaList,
  getMedia,
  getAlbumMedia,
  albumList,
  uploadEditedImage,
  getAlbumProfile,
  changeCover,
  updateMediaDate,
  updateAlbumPrivacy,
  saveChildrenInMedia,
  addChildToMedia,
  removeChildFromMedia,
  loadMedias
};

function load(diary, page) {
  return apiClient.get("/diary/" + diary + "/album/?page=" + page);
}
function albumList(diary) {
  return apiClient.get("/diary/" + diary + "/album/list");
}
function create(diary, data) {
  return apiClient.post("/diary/" + diary + "/album", data);
}
function update(id, data) {
  return apiClient.put("/diary/album/" + id, data);
}
function _delete(id) {
  return apiClient.delete("/diary/album/" + id);
}
function getAlbumProfile(diary, name) {
  return apiClient.get("/diary/" + diary + "/album/" + name + "/profile");
}
function loadAlbum(diary, name, page) {
  return apiClient.get(
    "/diary/" +
      diary +
      "/album/" +
      name +
      "/?page=" +
      page
  );
}
function uploadImage(id, imageFile) {
  var formData = new FormData();
  formData.append("image", imageFile);

  return apiClient.post("/diary/album/" + id + "/media", formData);
}
function deleteMedia(id) {
  return apiClient.delete("/diary/media/" + id);
}
function updateMedia(id, data) {
  return apiClient.put("/diary/media/" + id, data);
}
function mediaList(id, page) {
  return apiClient.get("/diary/album/" + id + "/media/list?page=" + page);
}
function getMedia(id) {
  return apiClient.get("/diary/media/" + id);
}
function saveChildrenInMedia(id, data) {
  return apiClient.patch("/diary/media/" + id + "/children", data);
}
function addChildToMedia(id, data) {
  return apiClient.post("/diary/media/" + id + "/child", data);
}
function removeChildFromMedia(id, child_id) {
  return apiClient.delete("/diary/media/" + id + "/child/" + child_id);
}

function getAlbumMedia(id, page) {
  return apiClient.get(
    "/diary/album/" + id + "/media?page=" + page
  );
}

function uploadEditedImage(id, imageFile) {
  var formData = new FormData();
  formData.append("image", imageFile);

  return apiClient.post("/diary/media/" + id + "/file", formData);
}
function changeCover(album_id, media_id) {
  return apiClient.put("/diary/album/" + album_id + "/cover/" + media_id);
}
function updateMediaDate(media_id, date) {
  return apiClient.put("/diary/media/" + media_id + "/date", { date: date });
}
function updateAlbumPrivacy(album_id, privacy) {
  return apiClient.put("/diary/album/" + album_id + "/privacy", { privacy: privacy });
}

function loadMedias(diary, page) {
  return apiClient.get("/diary/" + diary + "/media?page=" + page);
}