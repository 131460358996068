<template>
  <form-dialog
    v-model="inputVal"
    :title="$t('Login')"
    max-width="450px"
    hide-submit
    prevent-mobile
    v-on:close="close"
  >
    <template v-slot:content>
      <v-alert outlined type="warning" prominent v-if="showSessionMessage">{{
        $t("SessionExpired")
      }}</v-alert>
      <v-form v-model="form" ref="form">
        <div class="text-center">
          <connect-button
            v-on:error="oError"
            v-on:success="success"
            class="mb-4 mt-4"
          ></connect-button>
        </div>
        <div class="text-center">{{ $t("OrWithEmail") }}</div>

        <v-text-field
          v-model="user.username"
          :rules="[(v) => !!v || $t('UsernameRequired')]"
          :disabled="is_authorizing"
          :label="$t('Username')"
          required
        ></v-text-field>

        <v-text-field
          v-model="user.password"
          :rules="[(v) => !!v || $t('PasswordRequired')]"
          :disabled="is_authorizing"
          :label="$t('Password')"
          :append-icon="show1 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          required
        ></v-text-field>

        <error-box :error="error"></error-box>

        <v-btn
          block
          :loading="is_authorizing && !fb_authorizing"
          :disabled="fb_authorizing"
          color="primary"
          @click="sendForm"
          class="text-none mt-3"
          >{{ $t("Button.Send") }}</v-btn
        >
        <div class="mt-3 text-center">
          {{ $t("NotRegistred") }}
          <router-link to="/signup">{{ $t("CreateAccount") }}</router-link>
          <br />
          {{ $t("Or") }}
          <router-link to="/forgotpassword">{{
            $t("ForgotPassword")
          }}</router-link
          >?
        </div>
      </v-form>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "Login": "Login",
    "SessionExpired": "Your session has expired! Please sign in again or you will be logged out.",
    "Username": "Username",
    "Password": "Password",
    "UsernameRequired": "Username is missing!",
    "PasswordRequired": "Password is missing!",
    "NotRegistred": "Not registred?",
    "CreateAccount" : "Create an account",
    "OrWithEmail": "Or use your e-mail",
    "Or": "or",
    "ForgotPassword": "Forgot Password"
    },
    "sv": {
    "Login": "Logga in",
    "SessionExpired": "Din inloggningsession har löpt ut. Vänligen logga in igen eller kommer du loggas ut.",
    "Username": "Användarnamn",
    "Password": "Lösenord",
    "UsernameRequired": "Användarnamn saknas!",
    "PasswordRequired": "Lösenord saknas!",
    "NotRegistred": "Inte medlem?",
    "CreateAccount" : "Skapa ett konto",
    "OrWithEmail": "Eller logga in med din email",
    "Or": "eller",
    "ForgotPassword": "Glömt ditt lösenord"
    }
    }
</i18n>

<script>
import { mapActions, mapState } from "vuex";
import ConnectButton from "../../components/global/ConnectButton.vue";

export default {
  name: "authorize_dialog",
  props: {
    showSessionMessage: {
      type: Boolean,
      required: false,
    },
    value: {},
  },
  data: () => ({
    error: null,

    form: false,
    show1: false,

    user: {
      password: "",
      username: "",
    },
  }),
  components: {
    "connect-button": ConnectButton,
  },
  computed: {
    ...mapState({
      is_authorizing: (state) => state.account.status.is_authorizing,
      fb_authorizing: (state) => state.account.status.fb_authorizing,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    ...mapActions({
      login: "account/login",
      logout: "account/logout",
    }),
    sendForm() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self
          .login(self.user)
          .then(function (response) {
            self.success(response.data);
          })
          .catch(function (error) {
            self.error = error;
          });
      }
    },
    success() {
      this.$emit("authorized");
      this.inputVal = false;
    },
    close() {
      this.logout();
      this.$emit("closed");
      this.inputVal = false;
    },
    oError(err) {
      this.$ajaxErrorNoty(err);
    },
  },
};
</script>