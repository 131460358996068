import i18n from "@/plugins/i18n";

export const childMemories = {
  getList,
};

function getList() {
  return [
    {
      id: 1,
      key: "pregnancy",
      title: i18n.t("Memories.Pregnancy"),
      description: i18n.t("Memories.PregnancyDesc"),
      image: "https://cdn.babiry.com/web/memories/pregnant_400.jpg",
      form: {
        estimated_birth: {},
        date: { label: i18n.t("MemoriesForm.DateText") },
      },
    },
    {
      key: "birth",
      id: 2,
      title: i18n.t("Memories.Birth"),
      description: i18n.t("Memories.BirthDesc"),
      image: "https://cdn.babiry.com/web/memories/birth_400.jpg",
      form: {
        place: { label: i18n.t("MemoriesForm.BirthPlaceText") },
        date: { label: i18n.t("MemoriesForm.BirthDateText") },
      },
      icon: "$vuetify.icons.fasBaby",
    },
    {
      key: "baptism",
      id: 3,
      title: i18n.t("Memories.Baptism"),
      description: i18n.t("Memories.BaptismDesc"),
      image: "https://cdn.babiry.com/web/memories/baptism_400.jpg",
      form: {
        place: { label: i18n.t("MemoriesForm.BaptismPlaceText") },
        date: { label: i18n.t("MemoriesForm.BaptismDateText") },
      },
      icon: "$vuetify.icons.fasBaby",
    },
    {
      key: "sitting",
      id: 4,
      title: i18n.t("Memories.SitUp"),
      description: i18n.t("Memories.SitUpDesc"),
      image: "https://cdn.babiry.com/web/memories/situp_400.jpg",
      form: {
        place: { label: i18n.t("MemoriesForm.WhereDidItHappenText") },
        date: { label: i18n.t("MemoriesForm.DateText") },
      },
      icon: "$vuetify.icons.fasBaby",
    },
    {
      key: "teeth",
      id: 5,
      title: i18n.t("Memories.FirstTeeth"),
      description: i18n.t("Memories.FirstTeethDesc"),
      image: "https://cdn.babiry.com/web/memories/teeth_400.jpg",
      form: { date: { label: i18n.t("MemoriesForm.DateText") } },
      icon: "$vuetify.icons.fasBaby",
    },
    {
      key: "crawl",
      id: 6,
      title: i18n.t("Memories.Crawl"),
      description: i18n.t("Memories.CrawlDesc"),
      image: "https://cdn.babiry.com/web/memories/crawl_400.jpg",
      form: {
        place: { label: i18n.t("MemoriesForm.WhereDidItHappenText") },
        date: { label: i18n.t("MemoriesForm.DateText") },
      },
      icon: "$vuetify.icons.fasBaby",
    },
    {
      key: "standup",
      id: 7,
      title: i18n.t("Memories.StandUp"),
      description: i18n.t("Memories.StandUpDesc"),
      image: "https://cdn.babiry.com/web/memories/standup_400.jpg",
      form: {
        place: { label: i18n.t("MemoriesForm.WhereDidItHappenText") },
        date: { label: i18n.t("MemoriesForm.DateText") },
      },
      icon: "$vuetify.icons.fasBaby",
    },
    {
      key: "firststep",
      id: 8,
      title: i18n.t("Memories.FirstStep"),
      description: i18n.t("Memories.FirstStepDesc"),
      image: "https://cdn.babiry.com/web/memories/walking_400.jpg",
      form: {
        place: { label: i18n.t("MemoriesForm.WhereDidItHappenText") },
        date: { label: i18n.t("MemoriesForm.DateText") },
      },
      icon: "$vuetify.icons.fasBaby",
    },
    {
      key: "firstwords",
      id: 9,
      title: i18n.t("Memories.FirstWords"),
      description: i18n.t("Memories.FirstWordsDesc"),
      image: "https://cdn.babiry.com/web/memories/talking_400.jpg",
      form: {
        place: {
          label: i18n.t("MemoriesForm.WhereDidItHappenText"),
        },
        date: { label: i18n.t("MemoriesForm.DateText") },
      },
      icon: "$vuetify.icons.fasBaby",
    },

    {
      key: "preschool",
      id: 10,
      title: i18n.t("Memories.Preschool"),
      description: i18n.t("Memories.PreschoolDesc"),
      image: "https://cdn.babiry.com/web/memories/kindergarten_400.jpg",
      form: {
        place: {
          label: i18n.t("MemoriesForm.PreschoolPlaceText"),
        },
        date: { label: i18n.t("MemoriesForm.DateText") },
      },
      icon: "$vuetify.icons.fasBaby",
    },
    {
      key: "birthday",
      id: 11,
      url_name: "birthday-1",
      years: 1,
      title: i18n.t("Memories.FirstBirthday"),
      description: i18n.t("Memories.FirstBirthdayDesc"),
      image: "https://cdn.babiry.com/web/memories/birthday_400.jpg",
      form: { date: { label: i18n.t("MemoriesForm.DateText") } },
      icon: "$vuetify.icons.fasBaby",
    },    
  ];
}
