var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('form-dialog',{attrs:{"title":_vm.$t('Welcome'),"submit-text":_vm.$t('Button.Done'),"max-width":"500px","is-saving":_vm.is_saving},on:{"submit":_vm.handleSubmit,"close":_vm.handleClose},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-form',{ref:"form",attrs:{"autocomplete":"off"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-alert',{staticClass:"body-2 mb-5",attrs:{"dense":"","outlined":"","color":"primary"}},[(_vm.settings.includes('username'))?_c('span',{staticClass:"pa-0",domProps:{"innerHTML":_vm._s(_vm.$t('WelcomeWithUsernameDesc'))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('WelcomeDesc'))}})]),(_vm.settings.includes('username'))?_c('v-text-field',{attrs:{"rules":[
            function (v) { return !!v || _vm.$t('UsernameRequired'); },
            function (v) { return (v && v.length <= 45) || _vm.$t('UsernameMaxLength'); },
            function (v) { return (v && v.length >= 3) || _vm.$t('UsernameToShort'); },
            function (v) { return /^[a-zA-Z0-9_.]+$/.test(v) || _vm.$t('UsernameNotValid'); } ],"disabled":_vm.is_saving,"label":_vm.$t('Username'),"autocorrect":"off","spellcheck":"false","autocomplete":"off","required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}):_vm._e(),(_vm.settings.includes('diary'))?_c('v-text-field',{attrs:{"rules":[
            function (v) { return !!v || _vm.$t('DiaryTitleIsMissing'); },
            function (v) { return (v && v.length <= 45) || _vm.$t('DiaryTitleMaxLength'); },
            function (v) { return (v && v.length >= 3) || _vm.$t('DiaryTitleToShort'); }
          ],"disabled":_vm.is_saving,"autocorrect":"off","spellcheck":"false","autocomplete":"off","label":_vm.$t('Title'),"required":""},model:{value:(_vm.diary_title),callback:function ($$v) {_vm.diary_title=$$v},expression:"diary_title"}}):_vm._e(),(_vm.settings.includes('diary'))?_c('v-text-field',{attrs:{"rules":[
            function (v) { return !!v || _vm.$t('DiaryNameIsMissing'); },
            function (v) { return (v && v.length <= 45) || _vm.$t('DiaryNameMaxLength'); },
            function (v) { return (v && v.length >= 3) || _vm.$t('DiaryNameToShort'); },
            function (v) { return /^[a-zA-Z0-9_.]+$/.test(v) || _vm.$t('DiaryNameNotValid'); } ],"disabled":_vm.is_saving,"hint":_vm.host + _vm.diary_name,"persistent-hint":"","autocorrect":"off","spellcheck":"false","autocomplete":"off","label":_vm.$t('DiaryName'),"required":""},model:{value:(_vm.diary_name),callback:function ($$v) {_vm.diary_name=$$v},expression:"diary_name"}}):_vm._e(),(_vm.settings.includes('diary'))?_c('v-select',{staticClass:"mt-5",attrs:{"items":[
            { text: _vm.$t('OnlyForMe'), value: _vm.$enums.DIARY_PRIVACY.OnlyForMe },
            { text: _vm.$t('OpenForAll'), value: _vm.$enums.DIARY_PRIVACY.OpenForAll },
            {
              text: _vm.$t('OnlyForMembers'),
              value: _vm.$enums.DIARY_PRIVACY.OnlyForMembers,
            } ],"disabled":_vm.is_saving,"required":"","label":_vm.$t('Availability')},model:{value:(_vm.diary_privacy),callback:function ($$v) {_vm.diary_privacy=$$v},expression:"diary_privacy"}}):_vm._e(),_c('error-box',{attrs:{"error":_vm.error}})],1)]},proxy:true}]),model:{value:(_vm.is_open),callback:function ($$v) {_vm.is_open=$$v},expression:"is_open"}}),_c('v-overlay',{attrs:{"value":_vm.page_loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }