export const enums = {
  SEXTYPES: {
    NOT_SET: 0,
    BOY: 1,
    GIRL: 2,
    OTHER: 3,
  },
  FEEDEVENTS: {
    BLOGPOST: 10,
    MEDIAALBUM: 20,
    CHILDBIRTH: 30,
    CHILDEVENT: 31,
    CHILDSIZE: 32,
    CHILDBIRTHDAYS: 33,
    CHILDMEMORY: 34,
    OWNEVENT: 40,
    INSTAGRAM: 50,
    FACEBOOK: 60,
    FACEBOOKPAGE: 61,
  },
  NOTIFICATIONTYPES: {
    AcceptedInvite: 5,

    GuestbookComment: 10,
    AnswerGuestbookComment: 11,
    GuestbookCommentToApprove: 12,
    GuestbookCommentApproved: 13,

    BlogPostComment: 20,
    AnswerBlogPostComment: 21,
    BlogPostCommentToApprove: 22,
    BlogPostCommentApproved: 23,

    MediaComment: 30,
    AnswerMediaComment: 31,
    MediaCommentToApprove: 32,
    MediaCommentApproved: 33,

    VideoUploaded: 40,
    VideoUploadFailed: 41,

    User: 90,
    UserSocialExpired: 91,
  },
  DIARYVISITSTATUS: {
    OK: 1,
    PRIVATE: 2,
    REQUIREPASSWORD: 3,
    REQUIREAUTHENTICATION: 4,
  },
  DIARY_PRIVACY: {
    OnlyForMe: 0,
    OpenForAll: 1,
    PasswordProtected: 2,
    OnlyForMembers: 5,
  },
  PRIVACY: {
    Private: 0,
    All: 1,
    Members: 2,
  },
  DIARY_ROLE: {
    Owner: 1,
    Admin: 2,
    Reader: 10,
  },
  DIARYSETTINGS: {
    Feed: "BlogFeedSettings",
    BlogPage: "BlogPageSettings",
    Guestbook: "GuestbookSettings",
    Comments: "CommentSettings",
    Menu: "MenuSettings",
  },
  DIARYPAGEPRIVACY: {
    OpenForAll: 1,
    FamilyAndFriends: 2,
    OnlyForMembers: 3,
  },
};
