import { apiClient } from '../../_helpers';

export const userMessageService = {
  inbox,
  delete: _delete,
  get,
  unread,
  outbox,
  create
};

function inbox(page) {
  return apiClient.get('user/message', {
      page: page,
      setAsSeen: true
  });
}
function _delete(id) {
  return apiClient.delete('user/message/' + id);
}
function get(id) {
  return apiClient.get('user/message/' + id);
}
function unread(page) {
  return apiClient.get('user/message/unread', {
    params: {
      page: page
    }
  });
}
function outbox(page) {
  return apiClient.get('user/message/outbox', {
    params: {
      page: page
    }
  });
}
function create(reciver, message) {
    return apiClient.post('user/' + reciver + '/message', {
        subject: message.subject,
        message: message.message
    });
}
