import { apiClient } from '../../_helpers';

export const userNotifications = {
  inbox,
  delete: _delete,
  setAllAsReaded,
  setAsReaded,
  setAsUnReaded
};

function inbox(page) {
  return apiClient.get('user/notifications', {
    page: page
  });
}
function _delete(id) {
  return apiClient.delete('user/notifications/' + id);
}
function setAllAsReaded() {
  return apiClient.put('user/notifications/readed/all');
}
function setAsReaded(ids) {
  return apiClient.post('user/notifications/readed', ids);
}
function setAsUnReaded(ids) {
  return apiClient.post('user/notifications/unreaded', ids);
}
