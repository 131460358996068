<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('SendUsFeedback')"
    v-on:submit="send"
    :submit-text="$t('Button.Send')"
    width="500px"
    :hide-submit="!current"
    :content-class="contentClass"
    v-on:close="onClose"
  >
    <template v-slot:content>
      <v-list class="mt-0 pt-0" v-if="current == null">
        <template v-for="(item, i) in items">
          <v-list-item :key="item.value" @click="show(item)">
            <v-list-item-avatar class="grey lighten-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <div v-text="item.subtitle" class="text-desc"></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="i + 1 < items.length"
            :key="'div-' + item.value"
          ></v-divider>
        </template>
      </v-list>

      <v-form v-model="form" ref="form" v-else>
        <v-textarea
          :label="$t('Message')"
          v-model="message"
          :disabled="is_saving"
          outlined
          required
          :rules="[(v) => !!v || $t('MessageRequired')]"
        ></v-textarea>

        <error-box :error="error"></error-box>
      </v-form>
    </template>
  </form-dialog>
</template>


<i18n>
    {
    "en": {
    "SendUsFeedback": "Give feedback",
    "Feedback": "Help us improve Babiry",
    "FeedbackDesc": "Are you missing something on Babiry?",
    "Error": "Report a problem",
    "ErrorDesc": "Let us know about a broken feature",
    "Message": "Message",
    "SuccessSendMail": "Message sent!",
    "MessageRequired": "Message is required!"
    },
    "sv": {
    "SendUsFeedback": "Lämna feedback",
    "Feedback": "Hjälp oss förbättra Barndagboken",
    "FeedbackDesc": "Saknar du något på Barndagboken?",
    "Error": "Anmäl ett problem",
    "ErrorDesc": "Meddela oss om funktioner som inte fungerar",
    "Message": "Meddelande",
    "SuccessSendMail": "Tack för ditt meddelande!",
    "MessageRequired": "Meddelande saknas!"
    }
    }
</i18n>


<script>
import { globalService } from "../../_services";
import { mapState } from "vuex";

export default {
  name: "feedback_dialog",
  data: () => ({
    is_open: false,
    error: null,
    is_saving: false,
    items: [],
    form: false,

    current: null,
    message: "",
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    contentClass: function () {
      if (!this.current) {
        return "pl-0 pr-0";
      }
      return "";
    },
  },
  methods: {
    open() {
      this.is_open = true;
      this.current = null;

      this.items = [
        {
          title: this.$t("Feedback"),
          subtitle: this.$t("FeedbackDesc"),
          icon: "$vuetify.icons.farComment",
          value: 1,
        },
        {
          title: this.$t("Error"),
          subtitle: this.$t("ErrorDesc"),
          icon: "$vuetify.icons.fasExclamationTriangle",
          value: 2,
        },
      ];
    },
    show(selected) {
      this.current = selected;
      this.message = "";
    },
    openMessage(subject) {
      this.current = {
        title: subject,
      };
      this.message = "";
      this.is_open = true;
    },
    onClose() {
      this.message = "";
    },
    send() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        globalService
          .sendFeedback(self.current.title, self.message)
          .then(function () {
            self.is_saving = false;

            self.message = "";
            self.$refs.form.resetValidation();
            self.is_open = false;
            self.$successNoty(self.$t("SuccessSendMail"));
          })
          .catch(function (error) {
            self.is_saving = false;
            self.error = error;
          });
      }
    },
  },
};
</script>

<style scoped>
.text-desc {
  color: gray;
}
</style>